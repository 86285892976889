import { ERuLocales } from '../enums/ruLocales.enum';
import { EWarrantyTypes } from '../models/offer.model';

export default {
  [ERuLocales.Car]: '0 машин | {n} машина | {n} машины | {n} машин',
  [ERuLocales.Apple]: 'нет бананов | {n} банан | {n} банана | {n} бананов',
  [ERuLocales.Item]: '0 товаров | {n} товар | {n} товара | {n} товаров',
  [ERuLocales.MarketplaceQuantity]: 'нет предложений | {n} предложение | {n} предложения | {n} предложений',
  [ERuLocales.MarketplaceQuantityNumber]: '0 предложений | {n} предложение | {n} предложения | {n} предложений',
  [ERuLocales.ItemsQuantity]: 'нет | {n} штука | {n} штуки | {n} штук',
  [EWarrantyTypes.Year]: 'нет | {n} год | {n} года | {n} лет',
  [EWarrantyTypes.Month]: 'нет | {n} месяц | {n} месяца | {n} месяцев',
  [EWarrantyTypes.Day]: 'нет | {n} день | {n} дня | {n} дней',
  [ERuLocales.DayAlternate]: '0 дней | {n} дня | {n} дней | {n} дней',
  [ERuLocales.Models]: 'нет моделей | {n} модель | {n} модели | {n} моделей',
  [ERuLocales.ParentCaseProducts]: 'нет товаров | {n} товара | {n} товаров | {n} товаров',
  [ERuLocales.FinalsCategories]: 'нет финальных категорий | {n} финальная категория | {n} финальные категории | {n} финальных категорий',
  [ERuLocales.Categories]: 'нет категорий | {n} категория | {n} категории | {n} категорий',
  [ERuLocales.Supplier]: '0 поставщиков | {n} поставщик | {n} поставщика | {n} поставщиков',
  [ERuLocales.Hour]: '0 часов | {n} час | {n} часа | {n} часов',
  [ERuLocales.Minute]: '0 минут | {n} минута | {n} минуты | {n} минут',
  [ERuLocales.SelectedCategories]: 'Нет выбранных категорий | Выбрана {n} категория | Выбрано {n} категории | Выбрано {n} категорий',
  [ERuLocales.SelectedBrands]: 'Нет выбранных брендов | Выбран {n} бренд | Выбрано {n} бренда | Выбрано {n} брендов',
  [ERuLocales.Addresses]: 'нет адресов | {n} адрес | {n} адреса | {n} адресов',
  [ERuLocales.SelectAdresses]: 'Выбрано 0 адресов | Выбран {n} адрес | Выбрано {n} адреса | Выбрано {n} адресов',
  [ERuLocales.ToClients]: '0 клиентам | {n} клиенту | {n} клиентам | {n} клиентам',
  [ERuLocales.ToBasis]: '0 базисам | {n} базису | {n} базисам | {n} базисам',
  [ERuLocales.AddToCart]: 'Добавлена 0 | Добавлена {n} | Добавлено {n}',
  [ERuLocales.Offer]: 'Нет офферов | {n} оффер | {n} оффера | {n} офферов',
  [ERuLocales.NotificationsUnreadCount]: '0 новых уведомлений | {n} новое уведомление | {n} новых уведомления  | {n} новых уведомлений',
  [ERuLocales.Day]: 'дней | {n} день | {n} дня  | {n} дней',
  [ERuLocales.ItemPosition]: 'нет товарных позиций | {n} товарную позицию | {n} товарные позиции | {n} товарных позиций',
  [ERuLocales.Source]: '0 источников | {n} источника | {n} источников | {n} источников',
  [ERuLocales.UpToDays]: 'нет | {n} дня | {n} дней| {n} дней',
  [ERuLocales.City]: '0 городов | {n} город | {n} города | {n} городов',
  [ERuLocales.Region]: '0 регионах | {n} регионе | {n} регионах | {n} регионах',
  [ERuLocales.NewMasculine]: '0 новых | {n} новый | {n} новых',
  [ERuLocales.NewNeuter]: '0 новых | {n} новое | {n} новых',
  [ERuLocales.ActiveMasculine]: '0 активных | {n} активный | {n} активных',
  [ERuLocales.Values]: '0 значений | {n} значение | {n} значения | {n} значений',
  [ERuLocales.CargoSpace]: '0 грузомест | {n} грузоместо | {n} грузоместа | {n} грузомест',
  [ERuLocales.CargoSpaceLong]: '0 грузовых мест | {n} грузовое место | {n} грузовых места | {n} грузовых мест',
  [ERuLocales.Orders]: '0 заказов | {n} заказ | {n} заказа | {n} заказов',
  [ERuLocales.Suborders]: '0 подзаказов | {n} подзаказ | {n} подзаказа | {n} подзаказов',
  [ERuLocales.UnitsNoCount]: 'единиц | единицу | единицы | единиц',
  [ERuLocales.ProductPositions]: '0 товарных позиций | {n} товарную позицию | {n} товарные позиции | {n} товарных позиций',
  [ERuLocales.FromSupplier]: '0 поставщиков | {n} поставщика | {n} поставщика | {n} поставщиков',
  [ERuLocales.SupplierOffer]: '0 поставщиков | {n} поставщика | {n} поставщиков | {n} поставщиков',
  [ERuLocales.Lists]: 'нет списков | {n} список | {n} списка | {n} списков',
  [ERuLocales.Needs]: 'потребностей включено | потребность включена | потребности включены | потребностей включены',
  [ERuLocales.Decimals]: '0 знаков | {n} знак | {n} знака | {n} знаков',
  [ERuLocales.FromOrders]: '0 заказов | {n} заказа | {n} заказов | {n} заказов',
  [ERuLocales.SubordersApprovedAndSendedSuppliers]:
    'будут согласованы и направлены в работу поставщикам | будет согласован и направлен в работу поставщику | будут согласованы и направлены в работу поставщикам | будут согласованы и направлены в работу поставщикам',
  [ERuLocales.SubordersText]: 'подзаказов | подзаказа | подзаказов | подзаказов',
  [ERuLocales.RegionCount]: 'нет регионов | {n} регион | {n} региона | {n} регионов',
  [ERuLocales.Organizations]: 'нет организаций | {n} организация | {n} организации | {n} организаций',
  [ERuLocales.Filters]: '0 фильтров, доступных | {n} фильтр, доступный | {n} фильтра, доступных | {n} фильтров, доступных',
  [ERuLocales.CatalogCategories]: '0 категорий | {n} категории | {n} категорий | {n} категорий',
  [ERuLocales.Realizations]: '0 реализаций | {n} реализация | {n} реализации | {n} реализаций',
};
