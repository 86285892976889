import ru from '../locales/ru';
import { customPluralizationRuleRu } from '../utils/customPluralizationRules.util';

const messages = {
  ru,
};

export default defineI18nConfig(() => ({
  locale: 'ru',
  fallbackLocale: 'ru',
  legacy: false,
  globalInjection: false,
  messages,
  lazy: true,
  pluralRules: {
    ru: customPluralizationRuleRu,
  },
}));
